import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '@/components/Container';
import HeaderTile from '@/components/HeaderTile';
import GradientText from '@/components/GradientText';
import TileWrapper from '@/components/TileWrapper';
import ImageTile from '@/components/ImageTile';
import TextTile from '@/components/TextTile';

const Consulting: React.FC = () => (
  <Container tag="section" id="consulting">
    <TileWrapper className="md:grid md:grid-cols-2">
      <HeaderTile
        className="md:col-span-2"
        title="Consulting"
        accent="yellow"
        image={
          <StaticImage
            src="../../resources/images/cubes-03.png"
            alt="3d geometric cubes flying around"
            className="object-cover w-full h-full"
            width={900}
          />
        }
      >
        <GradientText accent="yellow">The services</GradientText> driving the
        next level of ancillary growth
      </HeaderTile>
      <TextTile
        className="md:order-last"
        theme="dark"
        accent="yellow"
        eyebrow="Move faster – Evolve smarter"
        title="Advisory commercial consulting"
      >
        <p>
          Our team of former executives from leading LCCs and FSCs with proven
          track records can provide advisory services focused on ancillary
          revenues, distribution, revenue management and branded fares.
        </p>
      </TextTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/consulting-features.jpg"
          alt="Text: Ancillary Revenues, Distribution, Branded Fares and Revenue Management"
          className="object-cover w-full h-full"
          width={900}
        />
      </ImageTile>
    </TileWrapper>
  </Container>
);

export default Consulting;
