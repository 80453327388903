import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import BoxVideoMp4 from '@resources/videos/box.mp4';
import BoxVideoWebm from '@resources/videos/box.webm';
import Container from '@/components/Container';
import TileWrapper from '@/components/TileWrapper';
import TextTile from '@/components/TextTile';
import ImageTile from '@/components/ImageTile';
import Hero from '@/blocks/Hero';

const Intro: React.FC = () => (
  <Container tag="section">
    <Hero />
    <TileWrapper className="md:grid md:grid-cols-2">
      <ImageTile video={{ mp4: BoxVideoMp4, webm: BoxVideoWebm }}>
        <StaticImage
          src="../../resources/videos/box.jpg"
          alt="Unfolding box animation video poster"
          className="object-cover w-full h-full"
          width={900}
        />
      </ImageTile>
      <TextTile
        theme="light"
        eyebrow="Move faster – Evolve smarter"
        title="The science driving airlines ancillary journey"
      >
        <p>
          AncillaryBox support airlines through the ancillary journey with data
          solutions, proprietary targeting algorithms and APIs to deliver high
          margin ancillary sales.
        </p>
      </TextTile>
      <TextTile
        theme="color"
        eyebrow="Move faster – Evolve smarter"
        title="Tailored ancillary revenue solutions"
      >
        <p>
          The AncillaryBox team combines the functional expertise of state of
          the art data science, web solutions and commercial experience at over
          100 airlines in the development of tailored ancillary revenue
          solutions.
        </p>
      </TextTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/tile-plane.jpg"
          alt="Airplane standing on the runway"
          className="object-cover w-full h-full"
          width={900}
        />
      </ImageTile>
    </TileWrapper>
  </Container>
);

export default Intro;
