import React, { useLayoutEffect, useRef, useState } from 'react';
import { MotionProps } from 'framer-motion';
import Tile, { TileProps } from '@/components/Tile';

export interface ImageTileProps extends TileProps {
  video?: {
    mp4?: string;
    webm?: string;
  };
  poster?: string;
}

const ImageTile: React.FC<ImageTileProps & MotionProps> = ({
  video,
  poster,
  children,
  ...props
}) => {
  const ref = useRef<HTMLElement>(null);
  const [isInViewport, setIsInViewport] = useState(false);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInViewport(entry.isIntersecting);
      },
      { rootMargin: '50% 0% 50% 0%' },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref]);

  return (
    <Tile innerClassName="absolute w-full h-full" {...props}>
      <figure
        className="relative block w-full h-full overflow-hidden transform-gpu"
        ref={ref}
      >
        {children}
        {video && (
          <video
            poster={poster}
            aria-hidden="true"
            preload="none"
            autoPlay
            loop
            muted
            playsInline
            className="absolute top-0 left-0 z-10 object-cover w-full h-full motion-reduce:hidden"
          >
            {isInViewport && video.webm && (
              <source src={video.webm} type="video/webm" />
            )}
            {isInViewport && video.mp4 && (
              <source src={video.mp4} type="video/mp4" />
            )}
          </video>
        )}
      </figure>
    </Tile>
  );
};

ImageTile.defaultProps = {
  video: undefined,
  poster: undefined,
};

export default ImageTile;
