import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ProductAIDIcon from '@resources/svg/product-aid.svg';
import ProductAIRIcon from '@resources/svg/product-air.svg';
import ProductAIMIcon from '@resources/svg/product-aim.svg';
import Container from '@/components/Container';
import HeaderTile from '@/components/HeaderTile';
import GradientText from '@/components/GradientText';
import TileWrapper from '@/components/TileWrapper';
import ProductTile from '@/components/ProductTile';
import ImageTile from '@/components/ImageTile';

const Products: React.FC = () => (
  <Container tag="section" id="products">
    <TileWrapper className="md:grid md:grid-cols-2">
      <HeaderTile
        className="md:col-span-2"
        title="Our Products"
        image={
          <StaticImage
            src="../../resources/images/cubes-01.png"
            alt="3d geometric cubes flying around"
            className="object-cover w-full h-full"
            width={900}
          />
        }
      >
        <GradientText>The science</GradientText> driving the next level of
        ancillary growth
      </HeaderTile>
      <ProductTile
        icon={ProductAIDIcon}
        name="AncillaryBox AI.D"
        title="Data Platform"
      >
        <p>
          The AI. D data platform combines data from multiple sources, including
          historic airline ticket data and proprietary industry datasets, to
          derive expenditure and behavioral insights to improve conversion
          rates.
        </p>
      </ProductTile>
      <ProductTile
        icon={ProductAIRIcon}
        name="AncillaryBox AI.R"
        title="API Solutions"
        theme="dark"
      >
        <p>
          AI. R provides an API solution for selected high margin third party
          products to plug into the booking process or post booking touch points
          to drive higher revenue per passenger.
        </p>
      </ProductTile>
      <ProductTile
        className="md:order-last"
        icon={ProductAIMIcon}
        name="AncillaryBox AI.M"
        title="Targeting Engine"
        theme="color"
      >
        <p>
          AI. M uses data feeds from AI.D plus AncillaryBox’s proprietary
          algorithms to personalize ancillary offering for each customer in the
          booking process and other touch points or to feed a CRM system.
        </p>
      </ProductTile>
      <ImageTile>
        <StaticImage
          src="../../resources/images/cubes-02.jpg"
          alt="3d geometric cubes"
          className="object-cover w-full h-full"
          width={900}
        />
      </ImageTile>
    </TileWrapper>
  </Container>
);

export default Products;
