import React from 'react';
import { MotionProps } from 'framer-motion';
import BioTile, { BioTileProps } from '@/components/BioTile';
import ImageTile from '@/components/ImageTile';

export interface VerticalBioTileProps extends BioTileProps {
  image: React.ReactNode;
  name: string;
  position: string;
}

const VerticalBioTile: React.FC<VerticalBioTileProps & MotionProps> = ({
  image,
  children,
  ...props
}) => (
  <div className="md:col-span-2 md:grid md:grid-cols-2 xl:grid-cols-1 xl:col-span-1">
    <ImageTile>{image}</ImageTile>
    <BioTile {...props}>{children}</BioTile>
  </div>
);

export default VerticalBioTile;
