import React from 'react';
import { m as motion, MotionProps, Variants } from 'framer-motion';
import Tile, { TileProps } from '@/components/Tile';
import GradientText from '@/components/GradientText';

export interface ProductTileProps extends TileProps {
  name: string;
  title: string;
  icon: React.ComponentClass<React.SVGProps<SVGSVGElement>>;
}

const ProductTile: React.FC<ProductTileProps & MotionProps> = ({
  name,
  title,
  icon: Icon,
  children,
  ...props
}) => {
  const childVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  return (
    <Tile {...props}>
      <div className="px-gutter py-55 lg:text-lg">
        <div className="max-w-prose-md">
          <header className="mb-10 text-fluid-2xl">
            {Icon && (
              <div
                aria-hidden="true"
                className="mb-20 w-120 md:w-100 xl:w-auto"
              >
                <Icon className="h-auto max-w-full" />
              </div>
            )}
            <motion.h2
              variants={childVariants}
              className="my-0 font-normal text-same"
            >
              <GradientText>{name}</GradientText>
            </motion.h2>
            <motion.h3
              variants={childVariants}
              className="my-0 font-normal text-same"
            >
              {title}
            </motion.h3>
          </header>
          <motion.div variants={childVariants}>{children}</motion.div>
        </div>
      </div>
    </Tile>
  );
};

export default ProductTile;
