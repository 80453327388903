import React from 'react';
import { m as motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import Plane from '@resources/svg/plane.svg';
import GradientText from '@/components/GradientText';

const Hero: React.FC = () => (
  <header className="relative shadow-xl">
    <figure className="sr-only lg:not-sr-only">
      <StaticImage
        src="../../resources/images/header.jpg"
        alt="AncillaryBox Hero Image"
        width={1800}
      />
    </figure>
    <div className="text-white bg-primary-900 px-gutter py-50 lg:flex lg:flex-col lg:justify-center lg:absolute lg:inset-x-gutter lg:inset-y-0 lg:bg-transparent lg:text-gray-800">
      <motion.div
        initial={{ opacity: 0, translateX: -30, translateY: 15 }}
        whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
        transition={{ duration: 1.5, ease: 'easeOut' }}
        viewport={{ once: true }}
      >
        <Plane className="text-primary-600" />
      </motion.div>
      <h1 className="my-25 text-fluid-2xl max-w-prose-xs md:text-fluid-3xl lg:my-15">
        <GradientText>Accelerating</GradientText> Airlines Ancillary Journey
      </h1>
      <p className="text-sm tracking-wider uppercase opacity-60 font-medium-sm max-w-prose lg:opacity-100">
        We support airlines through the ancillary journey with state of the art
        data science solutions.
      </p>
    </div>
  </header>
);

export default Hero;
