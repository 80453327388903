import React, { useCallback } from 'react';
import { m as motion, MotionProps, Variants } from 'framer-motion';
import Tile, { TileProps } from '@/components/Tile';

export interface TextTileProps extends TileProps {
  eyebrow?: string;
  title: string;
}

const TextTile: React.FC<TextTileProps & MotionProps> = ({
  theme,
  accent,
  eyebrow,
  title,
  children,
  ...props
}) => {
  const getAccentClassName = useCallback(
    (accent: TextTileProps['accent']) => {
      if (accent === 'yellow') {
        switch (theme) {
          case 'light':
            return 'text-yellow-600';
          default:
            return 'text-yellow-500';
        }
      }

      switch (theme) {
        case 'light':
          return 'text-primary-600';
        default:
          return 'text-primary-500';
      }
    },
    [theme],
  );

  const childVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  return (
    <Tile theme={theme} {...props}>
      <div className="px-gutter py-55 lg:text-lg">
        <div className="border-b border-current pb-25 max-w-prose-md">
          <header className="mb-10">
            {eyebrow && (
              <motion.div
                variants={childVariants}
                className={`text-xs mb-10 font-semibold tracking-wider uppercase md:text-sm lg:text-base xl:text-lg ${getAccentClassName(
                  accent,
                )}`}
              >
                {eyebrow}
              </motion.div>
            )}
            <motion.h2 variants={childVariants} className="my-0">
              {title}
            </motion.h2>
          </header>
          <motion.div variants={childVariants}>{children}</motion.div>
        </div>
      </div>
    </Tile>
  );
};

TextTile.defaultProps = {
  eyebrow: undefined,
};

export default TextTile;
