import React from 'react';
import { m as motion, MotionProps, Variants } from 'framer-motion';
import Tile, { TileProps } from '@/components/Tile';

export interface HeaderTileProps extends TileProps {
  title: string;
  theme?: TileProps['theme'];
  image?: React.ReactNode;
}

const HeaderTile: React.FC<HeaderTileProps & MotionProps> = ({
  title,
  image,
  aspect,
  children,
  ...props
}) => {
  const childVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  return (
    <Tile
      innerClassName="grid w-full grid-cols-1 md:grid md:grid-cols-2"
      aspect={aspect ?? 'wide'}
      {...props}
    >
      <div className="md:order-last">{image && image}</div>
      <div className="flex flex-col-reverse items-center justify-center px-gutter py-50 lg:text-lg">
        <div className="flex flex-col-reverse justify-center gap-y-25 max-w-prose-md md:max-w-prose-lg">
          <motion.div
            variants={childVariants}
            className="max-w-[13em] font-medium tracking-wider uppercase border-t border-b border-current py-5-fixed lg:text-lg"
          >
            {title}
          </motion.div>
          <motion.div
            variants={childVariants}
            className="text-fluid-2xl md:text-fluid-3xl"
          >
            {children}
          </motion.div>
        </div>
      </div>
    </Tile>
  );
};

HeaderTile.defaultProps = {
  theme: 'dark',
  image: undefined,
};

export default HeaderTile;
