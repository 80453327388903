import React, { useCallback } from 'react';
import { m as motion, MotionProps, Variants } from 'framer-motion';
import Tile, { TileProps } from '@/components/Tile';

export interface BioTileProps extends TileProps {
  name: string;
  position: string;
}

const BioTile: React.FC<BioTileProps & MotionProps> = ({
  name,
  position,
  theme,
  accent,
  title,
  children,
  ...props
}) => {
  const getAccentClassName = useCallback(
    (accent: BioTileProps['accent']) => {
      if (accent === 'yellow') {
        switch (theme) {
          case 'light':
            return 'text-yellow-600';
          default:
            return 'text-yellow-500';
        }
      }

      switch (theme) {
        case 'light':
          return 'text-primary-600';
        default:
          return 'text-primary-500';
      }
    },
    [theme],
  );

  const childVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  return (
    <Tile theme={theme} innerClassName="h-full" {...props}>
      <div className="h-full p-gutter lg:text-lg">
        <div className="flex flex-col h-full max-w-prose-md">
          <header className="mb-10">
            <motion.h2 variants={childVariants} className="my-0">
              {name}
            </motion.h2>
            <motion.div
              variants={childVariants}
              className={`flex-1 text-xs uppercase font-semibold tracking-wider md:text-sm ${getAccentClassName(
                accent,
              )}`}
            >
              {position}
            </motion.div>
          </header>
          <motion.div
            className="flex flex-col items-stretch justify-center flex-1"
            variants={childVariants}
          >
            <div>{children}</div>
          </motion.div>
        </div>
      </div>
    </Tile>
  );
};

export default BioTile;
