import React from 'react';
import { m as motion, MotionProps } from 'framer-motion';

export interface TileWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const TileWrapper: React.FC<TileWrapperProps & MotionProps> = ({
  children,
  ...props
}) => (
  <motion.div
    whileInView="visible"
    initial="hidden"
    viewport={{ once: true, margin: '-10% 0% -10% 0%' }}
    variants={{
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.2,
        },
      },
    }}
    {...props}
  >
    {children}
  </motion.div>
);

export default TileWrapper;
